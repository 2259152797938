






















import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import Session from '@/models/graphql/Session';
import LiveQuestionComponent from '@/components/live-session/LiveQuestionComponent.vue';
import QrLiveComponent from '@/components/live-session/QrLiveComponent.vue';

const sessionStore = namespace('SessionStore');

@Component({
  components: {
    QrLiveComponent,
    LiveQuestionComponent,
  },
})
export default class LiveQnAPresentationWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
    @Prop({ required: false, default: null })
    private entityCode!: string;

    @Prop({ required: false, default: null })
    private qrCodeUrl!: string;

    @sessionStore.Action
    private loadLiveSession!: (payload: { uid: string }) => Promise<Session | undefined>;

    private session: Session | null = null;

    private get route(): string {
      if (this.qrCodeUrl) {
        return this.qrCodeUrl;
      }
      const currentURL = window.location;
      return currentURL.origin + this.$router.resolve({ name: 'session-detail', params: { sessionId: this.uid } }).href;
    }

    private get uid(): string {
      let r = this.entityCode;
      const matches = this.entityCode.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          r = r.replaceAll(m, this.$route.params[prop]);
        });
        return r;
      }
      return this.entityCode;
    }

    created(): void {
      this.setDataConfig();
    }

    @Watch('entityCode')
    LoadQnAQuestions(): void {
      if (this.uid) {
        this.loadLiveSession({
          uid: this.uid,
        }).then((response) => {
          if (response) {
            this.session = response;
          }
        });
      }
    }
}
